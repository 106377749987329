
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import ContainerGrid from 'src/components/container-grid';
import HeadTags from 'src/components/head-tags';
import Page from 'src/components/page';
import RenderClientOnly from 'src/components/render-client-only';
import Spinner from 'src/components/spinner';
import useSdk, { GeneratePinCodeMutation } from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { useIsAuthenticated } from 'src/state/auth';
import { serverSideWithoutQuery } from 'src/utilities/ssr-helpers';
/**
 * Calculate the time until the PIN code expires and should be refreshed from the API
 * to make sure the user always has a valid PIN code
 * @param latestData
 * @returns
 */
const calculateTimeUntilExpiry = (latestData?: GeneratePinCodeMutation) => {
    // Important! - if the data is not available yet, we should try to refresh the PIN code in a second
    if (!latestData)
        return 1;
    // Disable automatic refresh if expiresAt is not set
    if (!latestData?.generatePinCode?.result?.expiresAt)
        return 0;
    // Calculate the PIN code expiry time
    const expiresAt = new Date(latestData.generatePinCode.result.expiresAt);
    // get the current time
    const now = new Date();
    // ms left until the PIN code expires
    const timeUntilExpiry = expiresAt.getTime() - now.getTime();
    // if the time is negative, the PIN code has already expired, refresh immediately
    return Math.max(0, timeUntilExpiry);
};
/**
 * The Connect page shows the user a PIN code that they can use to connect their account to their Apple/Android TV.
 * The PIN code is generated by the backend and is valid for 15 minutes. After the pin code expires, it is automatically refreshed.
 * By default the PIN code is 4 digits long, but the user can click a button to show 8 digits required in the STAGE+ TV Apps before October 2023
 *
 * @note This connect implementation is different to the tv auth page, which provides a form for users to enter a code to authorize their TV device.
 */
function ConnectPage(): JSX.Element {
    const t = useTranslate();
    const sdk = useSdk();
    const { replace, query, isReady } = useRouter();
    const isLoggedIn = useIsAuthenticated();
    // By default we show 4 digits, but the user can click a button to show 8 digits for older TV apps
    const [showFourDigits, setShowFourDigits] = useState(true);
    const { data, isLoading, isValidating, mutate: refreshPinCode, } = useSWR(isLoggedIn ? 'pinCode' : undefined, // Only fetch the PIN code if the user is logged in
    () => sdk.generatePinCode(), {
        refreshInterval: calculateTimeUntilExpiry,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });
    // The API only checks the first 4 digits of the PIN code, so we can safely only show the first 4 digits to the user
    const pinCode = data?.generatePinCode?.result?.pinCode?.split('').slice(0, showFourDigits ? 4 : 8);
    // If the user is not logged in, redirect them to login page, make sure they are redirected back to this page after login
    useEffect(() => {
        // If the user is not logged in, redirect them to the login page
        if (!isLoggedIn) {
            // Generate a redirect url to the login page
            const redirectUrl = new URL('/account/signup', window.location.href);
            // Make sure the login page redirects back to this page after login
            redirectUrl.searchParams.set('redirect', `/connect${window.location.search}`);
            // Make sure the login page does not show the navigation
            redirectUrl.searchParams.set('hideNav', '1');
            // Redirect the user to the login page
            void replace(redirectUrl.href);
        }
    }, [isLoggedIn, isReady, query, replace]);
    /**
     * When the user clicks on the button to toggle between 4 and 8 digits, we refresh the PIN code.
     * This is not strictly necessary, but it is nice to show a completely new PIN code when the user switches between 4 and 8 digits.
     */
    async function handleToggleDigitsButtonClick() {
        await refreshPinCode();
        setShowFourDigits((currentValue) => !currentValue);
    }
    return (<Page testId="connect-page">
      <HeadTags title={t('page__connect_pin_title')} noindex nofollow/>
      {isLoggedIn ? (<RenderClientOnly>
          <ContainerGrid>
            <div className="col-span-full mb-24 mt-12 lg:col-span-4 lg:col-start-5">
              <h1 className="dg-text-medium-2 mb-8 text-center">{t('page__connect_headline')}</h1>
              <p className="dg-text-regular-4 mb-8 text-center text-white text-opacity-70">{t('page__connect_text')}</p>
              {isValidating || isLoading ? (<Spinner className="mx-auto size-12"/>) : (<>
                  {/* PIN code */}
                  {pinCode && (<div data-test="pin-code" className="dg-text-medium-2 flex justify-center gap-x-2 text-center lining-nums">
                      {pinCode.map((value, index) => (<div key={index} className="h-10 w-8 shrink-0 md:w-10 lg:w-16">
                          {value}
                        </div>))}
                    </div>)}
                  {/* Button to toggle between 4 and 8 digits */}
                  <div className="mt-8 text-center">
                    <button onClick={handleToggleDigitsButtonClick} className="dg-text-regular-4 lining-nums underline">
                      {t(showFourDigits ? 'page__connect_eight_digit_button' : 'page__connect_four_digit_button')}
                    </button>
                  </div>
                </>)}
            </div>
          </ContainerGrid>
        </RenderClientOnly>) : null}
    </Page>);
}
// it should not be possible to register a new account or buy a subscription
// with the Connect page, as per App Store requirements
ConnectPage.hideNavigation = true;
const getStaticProps = serverSideWithoutQuery();
export default ConnectPage;

    async function __Next_Translate__getStaticProps__19505189747__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/connect',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19505189747__ as getStaticProps }
  